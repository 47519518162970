import tooltip from "bootstrap";
window.summernote = require("summernote/dist/summernote-bs4");

$(function() {
    // Summernote
    $('#summernote').summernote({
        tabsize: 2,
        height: 200,
        toolbar: [
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript',
                'subscript', 'clear'
            ]],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ol', 'ul', 'paragraph', 'height']],
            ['table', ['table']],
            ['insert', ['link']],
            ['view', ['undo', 'redo', 'fullscreen', 'codeview', 'help']]
        ]
    });

    $('.dropdown-toggle').dropdown();

})
