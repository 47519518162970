window.$ = window.jQuery = require('jquery');
require('./bootstrap');
window.toastr = require('toastr/toastr');

window.Chart = require('chart.js/dist/Chart.min');
window.multiSelect = require("multiselect/js/jquery.multi-select");

require('select2/dist/js/select2.full.min');

require('./tags-input');

window.dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
};

window.maskMoney = require('jquery-maskmoney/dist/jquery.maskMoney.min');

require('jquery-mask-plugin');

require('./summernote');

require('./bootstrap-iconpicker.bundle.min');

$(document).ready(function(){
    var origin = window.location.origin;
    var pathname = window.location.pathname;
    const url = origin+'/'+pathname.split('/')[1];

    $('ul.nav-sidebar a').filter(function() {
        return this.href == url;
    }).parent().addClass('active');

    $('ul.nav-treeview a').filter(function() {
        return this.href == url;
    }).parentsUntil(".sidebar-menu > .nav-treeview").addClass('menu-open');

    $('ul.nav-treeview a').filter(function() {
        return this.href == url;
    }).addClass('active');

    $('li.has-treeview a').filter(function() {
        return this.href == url;
    }).addClass('active');

    $('ul.nav-treeview a').filter(function() {
        return this.href == url;
    }).parentsUntil(".sidebar-menu > .nav-treeview").children(0).addClass('active');
});

$(function () {
    $('.bootstrap-tagsinput').addClass("form-control");

    $('input').on('change', function (event) {

       var $element = $(event.target);
       var $container = $element.closest('.example');

       if (!$element.data('tagsinput'))
          return;

       var val = $element.val();
       if (val === null)
          val = "null";
       var items = $element.tagsinput('items');

       $('code', $('pre.val', $container)).html(($.isArray(val) ? JSON.stringify(val) : "\"" + val.replace('"', '\\"') + "\""));
       $('code', $('pre.items', $container)).html(JSON.stringify($element.tagsinput('items')));


    }).trigger('change');

    $('.money').maskMoney({
        'prefix': 'R$',
        'thousands': '.',
        'decimal': ',',
        'affixesStay': false
    });
 });

